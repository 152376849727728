import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import { LoadingContext } from '../Contexts/LoadingContext';
import 'react-datepicker/dist/react-datepicker.module.css'
import './ViewApplication.css'; // Assuming you'll handle sliding window styles here



const ViewApplications = () => {
  const { jobId } = useParams(); // Get jobId from URL
  const [applications, setApplications] = useState([]);
  const [filteredApplications, setFilteredApplications] = useState([]);
  const [statusFilter, setStatusFilter] = useState(""); // For status filter
  const [selectedApplication, setSelectedApplication] = useState(null); // For the sliding window
  const [error, setError] = useState(null);
  const { setLoading } = useContext(LoadingContext);

  useEffect(() => {
    const fetchApplications = async () => {
      try {
        setLoading(true);
        const token = localStorage.getItem('authToken');
        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URI}/jobs/alljobs/${jobId}/applications`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setApplications(res.data.data);
        setFilteredApplications(res.data.data);
      } catch (err) {
        setError('Failed to fetch applications');
      } finally {
        setLoading(false);
      }
    };

    fetchApplications();
  }, [jobId, setLoading]);

  const handleStatusChange = async (applicationId, status) => {
    try {
      const token = localStorage.getItem('authToken');
      // Use the provided route for status change (approve, reject, hold)
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URI}/jobs/alljobs/applications/${applicationId}/status`,
        { status },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      alert(`Application ${status.toLowerCase()} successfully!`);
      // Optional: Update the application status locally after the change
      setApplications(applications.map(app => 
        app._id === applicationId ? { ...app, status } : app
      ));
    } catch (err) {
      alert('Failed to update application status');
    }
  };

  const handleCustomMessage = async (applicationId, status, customMessage) => {
    try {
      const token = localStorage.getItem('authToken');
      await axios.post(`${process.env.REACT_APP_BACKEND_URI}/jobs/alljobs/applications/${applicationId}/custom-message`, {
        status,
        message: customMessage || '',
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      alert('Application status updated successfully');
      // Optional: Refresh application status after the change
      setApplications(applications.map(app => 
        app._id === applicationId ? { ...app, status } : app
      ));
    } catch (err) {
      alert('Failed to update application status');
    }
  };

    // Function to filter applications by status
    // Function to filter applications by status
  const handleStatusFilterChange = (e) => {
    setStatusFilter(e.target.value);
    applyFilters(e.target.value);
  };
  
  // Automatically filter applications based on the selected status
  const applyFilters = (selectedStatus) => {
    let filtered = applications;

    // Apply status filter if selected
    if (selectedStatus) {
      filtered = filtered.filter(app => app.status === selectedStatus);
    }

    setFilteredApplications(filtered); // Update the displayed applications
  };


  const handleApplicationClick = (application) => {
    setSelectedApplication(application); // Open the sliding window
    document.body.classList.add('no-scroll');
  };

  const closeWindow = () => {
    setSelectedApplication(null); // Close the sliding window
    document.body.classList.remove('no-scroll');
  };

  if (error) {
    return <p>{error}</p>;
  }

  if (!applications.length) {
    return <p>No applications found for this job.</p>;
  }

  const formatDateToIST = (utcDate) => {
    const date = new Date(utcDate);
    const options = {
      timeZone: 'Asia/Kolkata',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true,
    };
    return date.toLocaleString('en-IN', options);
  };


  //const formattedRange = `${format(range[0].startDate,'dd-MM-yyyy')} to ${format(range[0].endDate,'dd-MM-yyyy')}`;

  return (
    <div className="applications-page">
      <h2>Applications for Job: {applications[0]?.job.title}</h2>
      <div className='filters-section'>
        <select value={statusFilter} onChange={handleStatusFilterChange}>
          <option value="">Filter by Status</option>
          <option value="Applied">Applied</option>
          <option value="Approved">Approved</option>
          <option value="On Hold">On Hold</option>
          <option value="Rejected">Rejected</option>
        </select>
      </div>

      <table>
        <thead>
          <tr>
            <th>Applicant</th>
            <th>Email</th>
            <th>Resume</th>
            <th>Status</th>
            <th>Applied</th>
          </tr>
        </thead>
        <tbody>
          {filteredApplications.map((application) => (
            <tr key={application._id} onClick={() => handleApplicationClick(application)}>
              <td>{application.user.name}</td>
              <td>{application.user.email}</td>
              <td><a href={application.resume.url} target="_blank" rel="noopener noreferrer">View Resume</a></td>
              <td className={`status-${application.status.replace(' ','-').toLowerCase()}`}>{application.status}</td>
              <td>{formatDateToIST(application.appliedAt)}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Sliding Window for Application Details */}
      {selectedApplication && (
        <div className={`sliding-window open`}>
          <div className="sliding-window-content">
            <button className="close-btn" onClick={closeWindow}>X</button>
            <h3>Application Details</h3>
            <p><strong>Name:</strong><Link to={`/admin/view-users/${selectedApplication.user._id}`} >{selectedApplication.user.name}</Link></p>
            <p><strong>Email:</strong> {selectedApplication.user.email}</p>
            <p><strong>Resume:</strong> <a href={selectedApplication.resume.url} target="_blank" rel="noopener noreferrer">View Resume</a></p>
            <p><strong>Cover Letter:</strong></p>
            <div className='coverLetter' dangerouslySetInnerHTML={{__html:selectedApplication.coverLetter}} />

            {/* Action Buttons */}
            <div className="actions">
              <button className="approve-btn" onClick={() => handleStatusChange(selectedApplication._id, 'Approved')}>Approve</button>
              <button className="reject-btn" onClick={() => handleStatusChange(selectedApplication._id, 'Rejected')}>Reject</button>
              <button className="hold-btn" onClick={() => handleStatusChange(selectedApplication._id, 'On Hold')}>Hold</button>
              <button className="custom-message-btn" onClick={() => {
                const customMessage = prompt('Enter a custom message:');
                if (customMessage) {
                  handleCustomMessage(selectedApplication._id, 'Custom Message', customMessage);
                }
              }}>
                Send Custom Message
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewApplications;
